import React, { useState, useEffect } from "react";
import NavBar from "./Components/NavBar/NavBar";
import Home from "./Components/Home/Home";
import Aboutus from "./Components/About/About";
import Clients from "./Components/Clients/Clients";
import Features from "./Components/Features/Features";
import Products from "./Components/OurProducts/Products";
import Contactus from "./Components/ContactUs/Contact";
import Footer from "./Components/Footer/Footer";
import HashLoader from "react-spinners/PacmanLoader";
import "./App.css";

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader">
          <HashLoader
            color={"#3d63ea"}
            loading={loading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="App">
          <NavBar />
          <Home />
          <Aboutus />
          <div className="container">
            <Features />
          </div>
          <div className="containers">
            <Products />
          </div>
          <div className="container">
            <Clients />
          </div>
          <Contactus />
          <Footer />
        </div>
      )}
    </>
  );
}

export default App;

import React from "react";
import "./Products.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUncharted } from "@fortawesome/free-brands-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const Products = () => {
  return (
    <div data-aos="fade-up" className="products-container">
      <div className="product-heading">
        <h4>Our Products </h4>
        <h2>Elevate Your Experience with Our </h2>
        <h2>Superior Products</h2>
      </div>
      <div className="card-container">
        <div className="card-product">
          <div className="card-content-product">
            <FontAwesomeIcon className="product-icon" icon={faUncharted} />
            <h3>Freight Forwarding ERP Software (SaaS)</h3>
            <p>
              In the contemporary landscape of global trade, the role of
              efficient freight forwarding cannot be overstated...
            </p>
            <div className="Client-btn" style={{ marginTop: "11%" }}>
              <Link className="btn prod-btn" to="/Freight-Forwarding">
                View More
                <FontAwesomeIcon className="btnArrow" icon={faArrowRight} />
              </Link>
            </div>
          </div>
        </div>
        <div className="card-product">
          <div className="card-content-product">
            <FontAwesomeIcon className="product-icon" icon={faUncharted} />
            <h3>NVOCC ERP Software (SaaS)</h3>
            <p>
              Are you tired of feeling like you are herding cats when it comes
              to managing your logistics operations? Say goodbye to the chaos
              and hello to smooth ...
            </p>
            <div className="Client-btn" style={{ marginTop: "12%" }}>
              <Link className="btn prod-btn" to="/NVOCC">
                View More
                <FontAwesomeIcon className="btnArrow" icon={faArrowRight} />
              </Link>
            </div>
          </div>
        </div>
        <div className="card-product">
          <div className="card-content-product">
            <FontAwesomeIcon className="product-icon" icon={faUncharted} />
            <h3>Transport ERP Software</h3>
            <p>
              Are you tired of feeling like your transportation business is
              running you instead of the other way around? Do you find yourself
              constantly losing track of bookings...
            </p>
            <div className="Client-btn" style={{ marginTop: "12%" }}>
              <Link className="btn prod-btn" to="/Transport">
                View More
                <FontAwesomeIcon className="btnArrow" icon={faArrowRight} />
              </Link>
            </div>
          </div>
        </div>
        <div className="card-product">
          <div className="card-content-product">
            <FontAwesomeIcon className="product-icon" icon={faUncharted} />
            <h3>Console(Sea / Air) Software</h3>
            <p>
              "Say goodbye to filing headaches with our Sea / Air Console
              customs filing software! No more sea-sickening paperwork or
              air-headed mistakes. Get your cargo cleared in a breeze ...
            </p>
            <div className="Client-btn" style={{ marginTop: "12%" }}>
              <Link className="btn prod-btn" to="/Console-Sea-Air">
                View More
                <FontAwesomeIcon className="btnArrow" icon={faArrowRight} />
              </Link>
            </div>
          </div>
        </div>
        <div className="card-product">
          <div className="card-content-product">
            <FontAwesomeIcon className="product-icon" icon={faUncharted} />
            <h3>IGM / EGM Software</h3>
            <p>
              Tired of dealing with customs paperwork? Say goodbye to the
              headache with our Import/Export General Manifest software! File
              faster, easier, and with a lot less stress...
            </p>
            <div className="Client-btn" style={{ marginTop: "12%" }}>
              <Link className="btn prod-btn" to="/IGM-EGM">
                View More
                <FontAwesomeIcon className="btnArrow" icon={faArrowRight} />
              </Link>
            </div>
          </div>
        </div>
        <div className="card-product">
          <div className="card-content-product">
            <FontAwesomeIcon className="product-icon" icon={faUncharted} />
            <h3>BREAK BULL Vessel Operator ERP Software (SaaS)</h3>
            <p>
              In the fast-paced world of break bulk vessel operations, where
              efficiency and precision are paramount, the manual management of
              such complex processes lead...
            </p>
            <div className="Client-btn" style={{ marginTop: "11%" }}>
              <Link className="btn prod-btn" to="/BBVP-ERP">
                View More
                <FontAwesomeIcon className="btnArrow" icon={faArrowRight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;

import React, { useState, useEffect } from "react";
import "./ClientDetails.css";
import ProductNav from "../ProductNav/ProductNav";

const ClientDetails = () => {
  const [customerData, setCustomerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  // eslint-disable-next-line
  const [filterLetter, setFilterLetter] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://hrm.f-studio.in/Service/GetClient.ashx"
        );
        const data = await response.json();
        if (data.Status === "Success") {
          setCustomerData(data.CustomerDetails);
          setFilteredData(data.CustomerDetails);
        } else {
          console.error("Failed to fetch data:", data.Message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleFilter = (letter) => {
    if (letter === "ALL") {
      setFilteredData(customerData);
    } else {
      const filtered = customerData.filter((customer) =>
        customer.Location.toUpperCase().startsWith(letter.toUpperCase())
      );
      setFilteredData(filtered);
    }
    setFilterLetter(letter);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="table-container">
      <ProductNav />
      <h2>Our Clients Details</h2>
      <div className="filter-buttons">
        <button onClick={() => handleFilter("ALL")}>All</button>
        {Array.from(Array(26)).map((_, index) => (
          <button
            key={index}
            onClick={() => handleFilter(String.fromCharCode(65 + index))}
          >
            {String.fromCharCode(65 + index)}
          </button>
        ))}
      </div>
      <table>
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Location</th>
            <th>Using From</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((customer, index) => (
            <tr key={index}>
              <td>{customer.CustomerName}</td>
              <td>{customer.Location}</td>
              <td>{customer.UsingFrom}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientDetails;

import React, { useState } from "react";
import "./NavBar.css";
import Logo from "../../assets/Vector.jpeg";
import { Link } from "react-scroll";
import Menu from "../../assets/menu.png";

function NavBar() {
  const [mobileMenu, setMobileMenu] = useState(false);

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const closeMobileMenu = () => {
    setMobileMenu(false);
  };

  return (
    <>
      <nav className="container dark-nav">
        <img src={Logo} className="logo" alt="" />
        <ul className={mobileMenu ? "" : "hide-mobile-menu"}>
          <li>
            <Link
              to="Home"
              smooth={true}
              offset={-155}
              duration={500}
              href="#Home"
              onClick={closeMobileMenu}
            >
              Home
            </Link>
          </li>

          <li>
            <Link
              to="about"
              smooth={true}
              offset={-180}
              duration={500}
              href="#about"
              onClick={closeMobileMenu}
            >
              About us
            </Link>
          </li>
          <li>
            <Link
              to="products-container"
              smooth={true}
              offset={-90}
              duration={500}
              href="#Products"
              onClick={closeMobileMenu}
            >
              Our Products
            </Link>
          </li>
          <li>
            <Link
              to="cont-heading"
              smooth={true}
              offset={-130}
              duration={500}
              href="#Clients"
              onClick={closeMobileMenu}
            >
              Our Clients
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              smooth={true}
              offset={-180}
              duration={500}
              href="#TechStack"
              onClick={closeMobileMenu}
            >
              Contact us
            </Link>
          </li>
          <li>
            <a className="btn" href="http://invosys.co.in/">
              Our Services
            </a>
          </li>
        </ul>
        <img src={Menu} alt="" onClick={toggleMenu} className="menu-icon" />
      </nav>
    </>
  );
}

export default NavBar;

import React, { useEffect } from "react";
import FFImg from "../../assets/gstFlow.png";
import FFImg2 from "../../assets/logistics.png";
import FFImg3 from "../../assets/Group 3587.png";
import "./ProductPage.css";
import "../Home/Home.css";
import ProductNav from "../ProductNav/ProductNav";

const BBVP = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="Home">
        <ProductNav />
        <div className="home-left" data-aos="fade-up">
          <h4>BREAK BULK VESSEL OPERATOR</h4>
          <p>
            In the fast-paced world of break bulk vessel operations, where
            efficiency and precision are paramount, the manual management of
            such complex processes can often lead to chaos and inefficiencies.
            The arduous task of juggling paperwork, tracking stock, managing
            vehicle loads, and overseeing vessel unloading can be overwhelming,
            leaving businesses struggling to maintain control and stay ahead of
            the competition. However, in the face of these challenges, a beacon
            of hope shines brightly in the form of our Break Bulk Vessel
            Operator ERP Software (SAAS).
          </p>
        </div>
        <div className="home-right">
          <img src={FFImg} className="home-img" alt="" />
        </div>
      </div>
      <div className="second-heading">
        <div className="FFHome-right" data-aos="fade-up">
          <img src={FFImg2} className="home-img" alt="" />
        </div>
        <div className="FFHome-left" data-aos="fade-up">
          <h4>
            Revolutionizing Break Bulk Operations with Streamlined Software
          </h4>
          <p>
            The software's functionality encompasses a wide array of tasks,
            starting from the moment a customer expresses interest in a
            shipment. It facilitates the generation of accurate sales quotes,
            aids in booking the shipment with ease, and culminates in the
            seamless handling of billing and accounting procedures. By
            encapsulating the entire logistics process within a unified digital
            framework, freight forwarding ERP software ensures that no detail is
            overlooked, and every operation is executed with meticulous care.
          </p>
          <p>
            But the benefits of our software extend far beyond mere operational
            convenience. By harnessing the power of data analytics, our solution
            enables users to generate comprehensive reports that offer valuable
            insights into stock levels, vehicle loads, and vessel activities.
            Armed with this invaluable information, businesses can make informed
            decisions, optimize their workflows, and drive continuous
            improvement across all facets of their operations.
          </p>
        </div>
      </div>
      <div className="second-heading">
        <div className="FFHome-right" data-aos="fade-up">
          <img src={FFImg3} className="home-img" alt="" />
        </div>
        <div className="FFHome-left" data-aos="fade-up">
          <h4>
            Empower Your Break Bulk Operations Mobile-Enabled ERP Solution
          </h4>
          <p>
            Real-time tracking and updates serve as another hallmark feature of
            freight forwarding ERP software, enabling businesses to maintain
            constant communication with customers and partners. This
            transparency and visibility into the status of shipments foster
            trust and confidence among stakeholders, leading to smoother
            operations and enhanced collaboration across the supply chain.
          </p>
          <p>
            By embracing our Break Bulk Vessel Operator ERP Software (SAAS),
            businesses can bid farewell to the days of drowning in paperwork and
            usher in a new era of efficiency, organization, and success. The
            transition to a digital, automated approach not only eliminates the
            inefficiencies and errors associated with manual processes but also
            empowers businesses to operate with agility, precision, and
            confidence.
          </p>
          <h4>Conclusion</h4>
          <p>
            In conclusion, the time has come to revolutionize the way you manage
            your break bulk vessel operations. Embrace the future of efficiency,
            embrace the future of organization, and embrace the future of
            success with our cutting-edge software solution. Try our software
            today and experience firsthand the transformative impact it can have
            on your business. Trust us, once you make the switch, you will
            wonder how you ever managed without it.
          </p>
        </div>
      </div>
    </>
  );
};

export default BBVP;

import React, { useState } from "react";
import "../NavBar/NavBar.css";
import Logo from "../../assets/Vector.jpeg";
import { Link } from "react-router-dom";
import Menu from "../../assets/menu.png";

function ProductNav() {
  const [mobileMenu, setMobileMenu] = useState(false);

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const closeMobileMenu = () => {
    setMobileMenu(false);
  };

  return (
    <>
      <nav className="container dark-nav">
        <img src={Logo} className="logo" alt="" />
        <ul className={mobileMenu ? "" : "hide-mobile-menu"}>
          <li>
            <Link to="/" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>

          <li>
            <Link to="/" onClick={closeMobileMenu}>
              About us
            </Link>
          </li>
          <li>
            <Link to="/" onClick={closeMobileMenu}>
              Our Products
            </Link>
          </li>
          <li>
            <Link to="/" onClick={closeMobileMenu}>
              Our Clients
            </Link>
          </li>
          <li>
            <Link to="/" onClick={closeMobileMenu}>
              Contact us
            </Link>
          </li>
          <li>
            <a className="btn" href="http://invosys.co.in/">
              Our Services
            </a>
          </li>
        </ul>
        <img src={Menu} alt="" onClick={toggleMenu} className="menu-icon" />
      </nav>
    </>
  );
}

export default ProductNav;

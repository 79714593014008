import React, { useEffect } from "react";
import FFImg from "../../assets/sea-air.png";
import "./ProductPage.css";
import "../Home/Home.css";
import ProductNav from "../ProductNav/ProductNav";

const IgmEgm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="Home">
        <ProductNav />
        <div className="home-left" data-aos="fade-up">
          <h4>IGM / EGM Software</h4>
          <p>
            Tired of dealing with customs paperwork? Say goodbye to the headache
            with our Import/Export General Manifest software! File faster,
            easier, and with a lot less stress. Try it now and start enjoying
            your life again! #NoMorePaperwork #Easy
          </p>
        </div>
        <div className="home-right">
          <img src={FFImg} className="home-img" alt="" />
        </div>
      </div>
      <div className="second-heading" style={{ marginTop: "-2%" }}>
        <div className="FFHome-left" data-aos="fade-up">
          <h4>Faster Filing Process</h4>
          <p>
            Our software accelerates the filing process, allowing you to submit
            import/export manifests swiftly and efficiently. Say goodbye to long
            hours spent on paperwork and experience quicker turnaround times for
            customs clearance.
          </p>
          <p>
            By adopting our Import/Export General Manifest software, you'll
            regain control over your operations without the stress of paperwork
            overload. Enjoy smoother transactions and a more organized approach
            to customs filing.
          </p>
          <p>
            Minimize the administrative burden associated with customs
            paperwork. Our software automates repetitive tasks, reducing the
            likelihood of errors and freeing up valuable time for more critical
            business activities.
          </p>
        </div>
      </div>
    </>
  );
};

export default IgmEgm;

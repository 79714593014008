import React from "react";
import "./About.css";
import About_img from "../../assets/become-a-partner_2x.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-scroll";
AOS.init({
  duration: 2500,
});

const Aboutus = ({ setPlayState }) => {
  return (
    <>
      <div data-aos="fade-up" className="about">
        <div className="about-left">
          <div className="about-heading">
            <h5 className="headings">About us</h5>
            <h2>
              Empowering Businesses with Invoking System's PVT LTD Expertise
            </h2>
          </div>
          <p>
            At Invosys, we believe in providing our clients with personalized
            and efficient logistics solutions ERP Software that meet their
            unique needs. With years of experience and a team of experts, we are
            dedicated to empowering your business with seamless shipping
            experiences.
          </p>
          <p className="moreinfo">
            <Link
              to="feat-content"
              smooth={true}
              offset={-200}
              duration={2000}
              href="#feature-down"
            >
              <button className="btn">Learn More</button>
            </Link>
          </p>
        </div>
        <div className="about-right">
          <img src={About_img} className="about-img" alt="" />
        </div>
      </div>
      <div data-aos="fade-up" className="card-row">
        <div className="about-card">
          <div className="about-card-content">
            <div className="number-container">
              <h2>01</h2>
              <div className="vertical-line"></div>
            </div>
            <p>Expertise and Experience</p>
          </div>
        </div>

        <div className="about-card">
          <div className="about-card-content">
            <div className="number-container">
              <h2>02</h2>
              <div className="vertical-line"></div>
            </div>
            <p>Committed to Quality</p>
          </div>
        </div>

        <div className="about-card">
          <div className="about-card-content">
            <div className="number-container">
              <h2>03</h2>
              <div className="vertical-line"></div>
            </div>
            <p>Comprehensive Services</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import "./index.css";
import ClientDetails from "./Components/Clients/ClientDetails";
import FFPage from "./Components/ProductPages/FFPage";
import Console from "./Components/ProductPages/Console";
import BBVP from "./Components/ProductPages/BBVP";
import IgmEgm from "./Components/ProductPages/IgmEgm";
import NVOCC from "./Components/ProductPages/NVOCC";
import Transport from "./Components/ProductPages/Transport";
import ProductNav from "./Components/ProductNav/ProductNav";

function RoutesContainer() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/" element={<ProductNav />} />
        <Route path="/More-Clients" element={<ClientDetails />} />
        <Route path="/Freight-Forwarding" element={<FFPage />} />
        <Route path="/Console-Sea-Air" element={<Console />} />
        <Route path="/BBVP-ERP" element={<BBVP />} />
        <Route path="/IGM-EGM" element={<IgmEgm />} />
        <Route path="/NVOCC" element={<NVOCC />} />
        <Route path="/Transport" element={<Transport />} />
      </Routes>
    </Router>
  );
}

export default RoutesContainer;

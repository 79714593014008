import React, { useEffect, useState } from "react";
import HomePng from "../../assets/HomePageImg.webp";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home.css";
AOS.init({
  duration: 3000,
});

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = HomePng;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  return (
    <div className="Home">
      <div className="circle-design">
        <div className="circle-design-1"></div>
        <div className="circle-design-2"></div>
        <div className="circle-design-3"></div>
        <div className="circle-design-4"></div>
        <div className="circle-design-5"></div>
        <div className="circle-design-6"></div>
        <div className="circle-design-7"></div>
        <div className="circle-design-8"></div>
      </div>
      <div className="home-left">
        <h4>
          Welcome to Invoking System Pvt Ltd Empowering Your Business with
          Innovative Technology Solutions
        </h4>
        <p>
          Simplify your shipping process and enhance efficiency with our
          F-Studio ERP Software & our services From document receive to document
          dispatch.
        </p>
        <Link
          to="about"
          smooth={true}
          offset={-180}
          duration={2000}
          href="#about-down"
        >
          <button className="btn-Home" type="">
            Let's get started!
          </button>
        </Link>
      </div>
      <div className="home-right">
        {imageLoaded ? (
          <img src={HomePng} className="home-img" alt="" />
        ) : (
          <div className="placeholder-img">Loading...</div>
        )}
      </div>
    </div>
  );
};

export default Home;

import React, { useRef, useEffect } from "react";
import FFImg from "../../assets/Group 48096122.png";
import FFImg3 from "../../assets/Group 3587.png";
import vehicle from "../../assets/vehicle.png";

import Route from "../../assets/route.svg";
import "./ProductPage.css";
import "../Home/Home.css";
import ProductNav from "../ProductNav/ProductNav";

const Transport = () => {
  const vehicleRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const vehiclePosition = vehicleRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (vehiclePosition < windowHeight * 0.8) {
        vehicleRef.current.classList.add("animateVehicle");
      } else {
        vehicleRef.current.classList.remove("animateVehicle");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="Home">
        <ProductNav />
        <div className="home-left" data-aos="fade-up">
          <h4>Transportation</h4>
          <p>
            Are you tired of feeling like your transportation business is
            running you instead of the other way around? Do you find yourself
            constantly losing track of bookings, waybills, and accounts? Are you
            sick of the chaos that comes with managing vehicle documents and
            spare parts stock? Well, say goodbye to all that stress and
            frustration because our Transportation ERP Software is here to save
            the day!
          </p>
        </div>
        <div className="home-right">
          <img src={FFImg} className="home-img" alt="" />
        </div>
      </div>
      <div className="Baggage">
        <div className="bag-left">
          <div className="image-container-trans">
            <img src={Route} className="RouteIcon" alt="Route" />
            <img
              src={vehicle}
              className="VechicleImage"
              ref={vehicleRef}
              alt="Vehicle"
            />
          </div>
        </div>
        <div className="bag-right" data-aos="fade-up">
          <h2>Master Your Transportation Operations with Ease</h2>
          <p>
            With our software, you can finally take control of your
            transportation business and say hello to organization and
            efficiency. From booking to printing waybills, billing to accounts,
            we have got all your bases covered. No more scrambling to find
            important documents or struggling to keep track of your stock. Our
            software will streamline your operations and make your life so much
            easier.
          </p>
          <p>
            But that is not all! Our Transportation ERP Software also allows you
            to manage vehicle materials with ease. No more wasting time
            searching for the right parts or dealing with inventory
            discrepancies. You will be able to keep everything in check and
            ensure that your vehicles are always running smoothly.
          </p>
        </div>
      </div>
      <div className="second-heading">
        <div className="FFHome-right" data-aos="fade-up">
          <img src={FFImg3} className="home-img" alt="" />
        </div>
        <div className="FFHome-left">
          <h4 data-aos="fade-up">
            Enhance Your Business Efficiency with Transportation ERP Software
          </h4>
          <p data-aos="fade-up">
            So, what are you waiting for? It is time to say goodbye to
            transportation troubles and hello to a more organized and efficient
            business. Try our Transportation ERP Software today and see the
            difference it can make for your company. Trust us, you won't know
            how you ever managed without it! #NoMoreTransportationTroubles
          </p>
        </div>
      </div>
    </>
  );
};
export default Transport;

import React, { useEffect } from "react";
import FFImg from "../../assets/sea-air.png";
import "./ProductPage.css";
import "../Home/Home.css";
import ProductNav from "../ProductNav/ProductNav";

const Console = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="Home">
        <ProductNav />
        <div className="home-left" data-aos="fade-up">
          <h4>Sea / Air Console</h4>
          <p>
            Say goodbye to filing headaches with our Sea / Air Console customs
            filing software! No more sea-sickening paperwork or air-headed
            mistakes. Get your cargo cleared in a breeze with our user-friendly
            and efficient software. Smooth sailing ahead!
          </p>
        </div>
        <div className="home-right">
          <img src={FFImg} className="home-img" alt="" />
        </div>
      </div>
      <div className="second-heading" style={{ marginTop: "-2%" }}>
        <div className="FFHome-left" data-aos="fade-up">
          <h4>Eliminate Paperwork Hassles</h4>
          <p>
            No more drowning in sea-sickening paperwork or making air-headed
            mistakes. Our software simplifies the entire customs filing process,
            reducing the need for manual paperwork and tedious data entry. Enjoy
            a paperless solution that saves time and minimizes errors.
          </p>
          <h4>User-Friendly Interface</h4>
          <p>
            Navigating customs regulations shouldn't be complicated. Our
            software features an intuitive interface that guides you through
            each step of the filing process. Easily input information, verify
            accuracy, and submit documents with confidence.
          </p>
        </div>
      </div>
    </>
  );
};

export default Console;

import React, { useEffect } from "react";
import FFImg from "../../assets/gstFlow.png";
import FFImg2 from "../../assets/ERP-for-Logistics.jpg";
import FFImg3 from "../../assets/Group 3587.png";
import "./ProductPage.css";
import "../Home/Home.css";
import ProductNav from "../ProductNav/ProductNav";

const FFPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="Home">
        <ProductNav />
        <div className="home-left" data-aos="fade-up">
          <h4>Freight Forwarding</h4>
          <p>
            In the contemporary landscape of global trade, the role of efficient
            freight forwarding cannot be overstated. It serves as the linchpin
            in ensuring the smooth flow of goods across borders and through
            various modes of transportation. In this complex web of logistics,
            the integration of Freight Forwarding Enterprise Resource Planning
            (ERP) software emerges as a pivotal solution for businesses aiming
            to not just survive, but thrive in the competitive market.
          </p>
        </div>
        <div className="home-right">
          <img src={FFImg} className="home-img" alt="" />
        </div>
      </div>
      <div className="second-heading">
        <div className="FFHome-right" data-aos="fade-up">
          <img src={FFImg2} className="home-img" alt="" />
        </div>
        <div className="FFHome-left" data-aos="fade-up">
          <h4>The Role of Freight Forwarding ERP Software</h4>
          <p>
            Freight forwarding ERP software stands as a comprehensive tool that
            revolutionizes the logistics process from start to finish. From the
            initial customer inquiry to the final accounting stages, this
            software seamlessly integrates and streamlines every aspect of the
            freight forwarding operation. It acts as a digital backbone,
            allowing companies to meticulously track and manage each step of the
            logistics journey with precision and efficiency.
          </p>
          <p>
            The software's functionality encompasses a wide array of tasks,
            starting from the moment a customer expresses interest in a
            shipment. It facilitates the generation of accurate sales quotes,
            aids in booking the shipment with ease, and culminates in the
            seamless handling of billing and accounting procedures. By
            encapsulating the entire logistics process within a unified digital
            framework, freight forwarding ERP software ensures that no detail is
            overlooked, and every operation is executed with meticulous care.
          </p>
        </div>
      </div>
      <div className="second-heading">
        <div className="FFHome-right" data-aos="fade-up">
          <img src={FFImg3} className="home-img" alt="" />
        </div>
        <div className="FFHome-left" data-aos="fade-up">
          <h4>Benefits of Freight Forwarding ERP Software</h4>
          <p>
            One of the most significant advantages of utilizing freight
            forwarding ERP software lies in its ability to enhance operational
            efficiency, accuracy, and customer service standards. By automating
            tasks that were previously reliant on manual data entry, the
            software significantly reduces the likelihood of errors and
            discrepancies. This not only saves valuable time and resources but
            also instills a higher degree of reliability and precision in the
            logistics process.
          </p>
          <p>
            Real-time tracking and updates serve as another hallmark feature of
            freight forwarding ERP software, enabling businesses to maintain
            constant communication with customers and partners. This
            transparency and visibility into the status of shipments foster
            trust and confidence among stakeholders, leading to smoother
            operations and enhanced collaboration across the supply chain.
          </p>
          <p>
            In essence, freight forwarding ERP software emerges as a
            transformative tool for companies seeking to optimize their
            logistics operations and elevate their competitive edge in the
            global trade arena. By meticulously tracking every stage of the
            process, from the initial customer inquiry to the final accounting
            procedures, businesses can ensure that their shipments are executed
            flawlessly, resulting in heightened customer satisfaction and
            improved profitability.
          </p>
          <h4 data-aos="fade-up">Conclusion</h4>
          <p data-aos="fade-up">
            In conclusion, the adoption of freight forwarding ERP software
            represents a strategic investment for businesses looking to navigate
            the complexities of modern global trade with finesse and efficiency.
            It serves as a beacon of innovation in the realm of logistics
            management, empowering companies to orchestrate seamless and
            successful shipments that propel them towards sustained growth and
            success in the dynamic world of international commerce.
          </p>
        </div>
      </div>
    </>
  );
};

export default FFPage;

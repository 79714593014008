import React, { useEffect } from "react";
import "./ProductPage.css";
import ProductNav from "../ProductNav/ProductNav";
import FFImg from "../../assets/gstFlow.png";
import FFImg2 from "../../assets/Untitled-design-2023-08-09T142851.410.png";
import FFImg3 from "../../assets/Group 3587.png";

const NVOCC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="Home">
        <ProductNav />
        <div className="home-left" data-aos="fade-up">
          <h4>NVOCC</h4>
          <p>
            Are you tired of feeling like you are herding cats when it comes to
            managing your logistics operations? Say goodbye to the chaos and
            hello to smooth sailing with our NVOCC ERP software!{" "}
          </p>
          <p>
            Picture this: You are sitting at your desk, sipping your morning
            coffee, and effortlessly tracking every shipment, every invoice, and
            every account with just a few clicks. No more pulling your hair out
            trying to keep up with the endless logistics jumble. Our software is
            here to save the day!
          </p>
        </div>
        <div className="home-right">
          <img src={FFImg} className="home-img" alt="" />
        </div>
      </div>
      <div className="second-heading">
        <div className="FFHome-right" data-aos="fade-up">
          <img
            src={FFImg2}
            style={{ marginBottom: "10%" }}
            className="home-img"
            alt=""
          />
        </div>
        <div className="FFHome-left" data-aos="fade-up">
          <h4>Revolutionize Your Logistics with Our NVOCC ERP Software</h4>
          <p>
            With our NVOCC ERP software, you can say goodbye to the days of lost
            shipments, missed deadlines, and endless paperwork. We have got your
            back every step of the way, from the initial enquiry to the final
            accounts. It is like having a logistics superhero by your side, but
            without the cape and spandex (unless that is your thing, no judgment
            here!).
          </p>
          <p>
            But wait, there is more! Not only will our software make your life
            easier, but it will also help you maximize profitability and
            streamline your operations like never before. It is like having a
            magic wand that turns chaos into order, confusion into clarity, and
            stress into... well, nothing because you will be stress-free!
          </p>
        </div>
      </div>
      <div className="second-heading">
        <div className="FFHome-right" data-aos="fade-up">
          <img src={FFImg3} className="home-img" alt="" />
        </div>
        <div className="FFHome-left" data-aos="fade-up">
          <h4>Upgrade Your Logistics Introducing Our NVOCC ERP Software</h4>
          <p>
            So why wait? Upgrade to our NVOCC ERP software today and experience
            the joy of seamless logistics management. Trust us, once you try it,
            you will wonder how you ever survived without it. It is time to
            level up your logistics game and make your competitors green with
            envy (or maybe just a little jealous, we don't want to cause any
            drama).
          </p>
          <p>
            Don't let logistics be a headache any longer. Embrace the future of
            efficient operations and take your business to new heights. Our
            software is waiting for you, ready to revolutionize the way you do
            logistics. Say hello to simplicity, profitability, and a whole lot
            of awesomeness. Upgrade now and get ready to conquer the logistics
            world like a boss!
          </p>
        </div>
      </div>
    </>
  );
};

export default NVOCC;

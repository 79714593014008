import React, { useEffect, useState } from "react";
import "./Clients.css";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Logo1 from "../../assets/Logo1.jpg";
import Logo2 from "../../assets/Logo2.jpg";
import Logo3 from "../../assets/Logo3.jpg";
import Logo4 from "../../assets/Logo4.jpg";
import Logo5 from "../../assets/Logo5.jpg";
import Logo6 from "../../assets/Logo6.jpg";
import Logo7 from "../../assets/Logo7.jpg";
import Logo8 from "../../assets/Logo8.jpg";
import Logo9 from "../../assets/Logo9.jpeg";
import Logo10 from "../../assets/Logo10.jpg";
import Logo11 from "../../assets/Logo11.jpg";
import Logo12 from "../../assets/Logo12.jpg";
import Logo13 from "../../assets/Logo13.jpg";
import Logo14 from "../../assets/Logo14.jpg";
import Logo15 from "../../assets/Logo15.jpg";
import Logo16 from "../../assets/Logo16.jpg";
import Logo17 from "../../assets/Logo17.jpg";
import Logo18 from "../../assets/Logo18.jpg";
import Logo19 from "../../assets/Logo19.jpg";
import Logo20 from "../../assets/Logo20.jpg";

const Clients = () => {
  const imageUrls = [
    Logo1,
    Logo2,
    Logo3,
    Logo4,
    Logo5,
    Logo6,
    Logo7,
    Logo8,
    Logo9,
    Logo10,
    Logo11,
    Logo12,
    Logo13,
    Logo14,
    Logo15,
    Logo16,
    Logo17,
    Logo18,
    Logo19,
    Logo20,
  ];

  const backgroundColors = [
    "#fbe0e6",
    "#f7f7e3",
    "#e5f1fb",
    "#dffbdb",
    "#f0f0f0",
    "#f7f7f7",
    "#effdf6",
    "#f0f0f0",
    "#f7f7f7",
    "#effdf6",
    "#f0f0f0",
    "#e5f1fb",
    "#dffbdb",
    "#fbe0e6",
    "#e5f1fb",
    "#E1F7F5",
    "#E8EFCF",
    "#edf0f3",
    "#C0D6E8",
    "#DDDDDD",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [imageUrls.length]);

  return (
    <>
      <h4 data-aos="fade-up" className="cont-heading">
        Our Clients{" "}
      </h4>
      <div data-aos="fade-up" className="image-grid">
        {imageUrls.map((imageUrl, index) => (
          <div
            key={index}
            className={`image-container ${
              index === currentIndex ? "active" : ""
            }`}
            style={{
              backgroundColor: backgroundColors[index],
              animationDelay: `${index * 5}s`,
            }}
          >
            <img src={imageUrl} alt={`other ${index + 1}`} />
          </div>
        ))}
      </div>
      <div className="Client-btn">
        <Link className="btn" to="/More-Clients">
          View More
          <FontAwesomeIcon className="Rightarrow" icon={faArrowDown} />
        </Link>
      </div>
    </>
  );
};

export default Clients;

import React from "react";
import "./Features.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faComments, faFlag } from "@fortawesome/free-regular-svg-icons";
import { faConnectdevelop } from "@fortawesome/free-brands-svg-icons";
import ImgFeature from "../../assets/FeaturesImg.webp";

const Features = () => {
  return (
    <>
      <div data-aos="fade-up" className="feat-content">
        <h5 className="headings">Features</h5>
        <h2>Overflowing with useful features</h2>
        <p>
          Powerful, self-serve product and growth analytics to help you convert,
          engage, and retain more users.
        </p>
      </div>

      <div data-aos="fade-up" className="Features">
        <div className="features-left">
          <div className="card">
            <div className="card-icon">
              <FontAwesomeIcon className="icons" icon={faComments} />
            </div>
            <div className="card-content">
              <h2>Share team inboxes</h2>
              <p>
                whether you have a team of 2 or 200 our shared team inboxes keep
                everyone on the same page and in the loop
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card-icon">
              <FontAwesomeIcon className="icons" icon={faLayerGroup} />
            </div>
            <div className="card-content">
              <h2>Deliver instant answers</h2>
              <p>
                An all-in-one customer service platform that helps you balance
                everything your customers need to be happy
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card-icon">
              <FontAwesomeIcon className="icons" icon={faFlag} />
            </div>
            <div className="card-content">
              <h2>Manage your team with reports</h2>
              <p>
                Messure what matters with Untitled's easy-to-use our reports.
                You can filter, export, and drilldown on the data in a couple
                clicks.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card-icon">
              <FontAwesomeIcon className="icons" icon={faConnectdevelop} />
            </div>
            <div className="card-content">
              <h2>Connect with customers</h2>
              <p>
                Solve a problem or close a sale in real-time with chat. If no
                one is available, customers are seamlessly routed to email
                without confusion.
              </p>
            </div>
          </div>
        </div>
        <div className="features-right">
          <img src={ImgFeature} alt="" />
        </div>
      </div>
    </>
  );
};

export default Features;
